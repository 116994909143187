<template>
  <v-data-table
    dense
    :loading="loading"
    :loading-text="$t('loading')"
    :headers="headers"
    :items="shipments"
    :items-per-page="20"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': $t('table.per-page')
    }"
    :no-data-text="$t('table.no-data')"
    :search="search"
    :options.sync="options"
    :server-items-length="total"
    :single-expand="true"
    :expanded.sync="expanded"
    show-expand
    @click:row="expandRow"
  >
    <template #top>
      <slot name="top"></slot>
    </template>
    <template #[`item.shipping_date`]="{ item }">
      <span>{{ date_to_str(item.shipping_date) }}</span>
    </template>
    <template #[`item.delivery_date`]="{ item }">
      <span>{{ date_to_str(item.delivery_date) }}</span>
    </template>
    <template #[`item.status`]="{ item }">
      {{ tStatus(item.status) }}
    </template>
    <template #[`item.actions`]="{ item }">
      <v-icon
        v-if="hasPermission('api.change_shipment')"
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        v-if="hasPermission('api.delete_shipment') && item.parcel_count == 0"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template #expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <more-info :shipment="item" :headers="headers" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import ShipmentService from '@/services/ShipmentService.js';
import shipmentMixin from './shipmentMixin.js';

export default {
  components: {
    moreInfo: () => import('@/views/dashboard/components/shipment/moreInfo.vue')
  },
  mixins: [shipmentMixin],
  props: {
    search: {
      type: String,
      default: ''
    },
    archived: {
      type: Boolean,
      required: false
    }
  },
  data: function () {
    return {
      searchTimeout: null,
      loading: false,
      expanded: [],
      shipments: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      options: {},
      total: 0
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
          sortable: true
        },
        {
          text: this.$t('shipments.client'),
          value: 'client_ro.name',
          sortable: true
        },
        {
          text: this.$t('shipments.parcels'),
          value: 'parcel_count',
          sortable: false
        },
        {
          text: this.$t('shipments.shipping-date'),
          value: 'shipping_date',
          sortable: true
        },
        {
          text: this.$t('shipments.delivery-date'),
          value: 'delivery_date',
          sortable: true
        },
        {
          text: this.$t('status'),
          value: 'status',
          sortable: true
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.loadItems();
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 500);
      }
    }
  },
  mounted() {
    this.$root.$on('shipment_updated', this.onShipmentUpdated);
    this.$root.$on('shipment_added', this.onShipmentAdded);
  },
  beforeDestroy() {
    this.$root.$off('shipment_updated', this.onShipmentUpdated);
    this.$root.$off('shipment_added', this.onShipmentAdded);
  },
  methods: {
    async loadItems() {
      this.loading = true;

      const statusFiltersActive = [
        {
          field: 'status',
          operation: '=',
          value: ['in preparation']
        },
        {
          field: 'status',
          operation: '=',
          value: ['to send']
        }
      ];

      const statusFiltersArchived = [
        {
          field: 'status',
          operation: '=',
          value: ['sent']
        },
        {
          field: 'status',
          operation: '=',
          value: ['canceled']
        }
      ];

      ShipmentService.getShipments(
        {
          ...this.options,
          filters: this.archived ? statusFiltersArchived : statusFiltersActive
        },
        this.searchPhrase ? this.searchPhrase : this.search
      )
        .then((response) => {
          let shipments;
          [shipments, this.total] = response;
          this.shipments = shipments.map(this.transform);

          this.$root.$emit('shipments:total', this.total);
          // console.log('shipments:', this.shipments);
        })
        .catch((err) => {
          this.showError(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editItem(item) {
      this.$emit('edit', Object.assign({}, item));
    },

    onShipmentAdded(shipment) {
      console.log('onShipmentAdded:', shipment);
      this.loadItems();
    },

    onShipmentUpdated(shipment) {
      console.log('onShipmentUpdated:', shipment);
      this.loadItems();
    },

    async deleteItem(item) {
      console.log('delete shipment:', item);
      const res = await this.$swal({
        html: this.$t('shipments.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.shipments.indexOf(item);

      try {
        let result = await ShipmentService.deleteShipment(item);
        console.log('result: ', result);
        this.shipments.splice(idx, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },

    date_to_str(date) {
      // return date
      if (!date) return '';
      return date.toLocaleString();
    }
  }
};
</script>

<style></style>
